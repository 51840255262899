import React from "react";
import Opportunities from "../components/Opportunities";
import Programs from "../components/Programs";
import LatestNews from "../components/LatestNews";
import Conference from "../components/Conference";

const Home = (prop) => {

  return (
    <>
      <div className="cover">
        <div className="overlay">
            <div className="title">
              <h3 className="md:text-5xl text-4xl mt-4">Innovative Composites for the Future of Sustainable Mining Equipment</h3>
              <h1 className="text-orange md:text-5xl md:mt-6 text-4xl mt-4">Australian Research Council Training Centre</h1>
              <p className="text-center sm:px-0 md:px-4 lg:px-8 text-lg md:mt-6 mt-4">The Centre aims to train and produce highly qualified professionals and engineering graduates. Our innovative program of industry-integrated research on innovative steel composites, coupled with work integrated learning, will empower graduates to develop unique solutions and incorporate the training outcomes into value-added technologies and products into the Australian METS sector.</p>
            </div>
            {/* <img className="cover-logo" src={logo} alt="logo" />
            <div class="red-line"></div> */}
        </div>
      </div>
      <Opportunities />
      <Conference />
      <Programs />
      <LatestNews />
    </>
  )
}

export default Home;