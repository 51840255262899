import React from "react";
import { Routes, Route } from "react-router-dom";
import { Site } from "./Site";
import Home from "./pages/Home";
import Overview from "./pages/Overview";
import OurPeople from "./pages/OurPeople";
import OurResearch from "./pages/OurResearch";
import Contact from "./pages/Contact";
import CurrentOpportunities from "./pages/CurrentOpportunities";
import Partners from "./components/Partners";
import Post from "./pages/Post";
import { NewsDetail } from "./components/NewsDetail";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Site />}>
        <Route index element={<Home />} />
        <Route path="overview" element={<Overview />} />
        <Route path="our-people" element={<OurPeople />} />
        <Route path="our-partners" element={<Partners />} />
        <Route path="news" element={<Post />}/>
        <Route path="news/:newsId" element={<NewsDetail />} />
        <Route path="our-research" element={<OurResearch />}>
          <Route path=":researchId" element={<OurResearch />} />
        </Route>
        <Route path="contact-us" element={<Contact />} />
        <Route path="current-opportunities" element={<CurrentOpportunities />} />
      </Route>
    </Routes>
  )
}

export default Routers;