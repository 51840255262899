import React from "react";
import conference from './../assets/image/conference.jpeg'
import GroupsIcon from '@mui/icons-material/Groups';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button } from "@mui/material";

const Conference = ( ) => {
  return (
      <div className="m-4">
        <h1 className="text-light-orange uppercase text-xl md:text-3xl font-bold">Our Conference</h1>
        <div className="flex flex-row flex-wrap justify-around md:m-8 lg:m-14 p-2 m-2">
          <div className="flex flex-col justify-between m-4 lg:basis-1/2 shrink">
            <div>
              <div>
                <GroupsIcon sx={{width: "30px"}}/><span className="text-xl font-semibold">The 6th International Symposium on Mine Hazards Prevention and Control Technology </span>
              </div>
              <div>
                <span className="text-xl font-semibold"> <GroupsIcon sx={{width: "30px"}}/>The 8th International Symposium on Dynamic Hazards in Underground Coal Mines</span>
              </div>
              <div className="mt-8 text-red-600">
                <span className="text-xl font-semibold"> <EventAvailableIcon sx={{width: "30px"}}/>1st - 3rd October 2024</span>
              </div>
              <div className="mt-4">
                <span><LocationOnIcon sx={{width: "30px"}}/>Wollongong, NSW, AUSTRALIA</span>
              </div>
            </div>
            <div className="flex flex-row flex-wrap">
              <div className="m-2">
                <a href="https://pay.uow.edu.au/GreenMiningConference2024" target="_blank" rel="noreferrer">
                  <Button variant="outlined" color="secondary" sx={{bgcolor: "#940707", color: "#fff", "&:hover": {bgcolor: "#940707", opacity: 0.9}, width: "200px", height:"60px", borderRadius: 0, border: 0, fontWeight: 600}}>Register Now</Button>
                </a>
              </div>
              <div className="m-2">
                <a href="https://smartmine.com.au/" target="_blank" rel="noreferrer">
                  <Button variant="outlined" color="secondary" sx={{bgcolor: "#1f2937", color: "#fff", "&:hover": {bgcolor: "#1f2937", opacity: 0.9}, width: "200px", height:"60px", borderRadius: 0, border: 0, fontWeight: 600}}>See more detail</Button>
                </a>
              </div>
            </div>
          </div>
          <div className="basis-full lg:basis-1/3 grow">
            <img src={conference} alt="conference-img" className="w-full" />
          </div>
        </div>
      </div>
    )
}

export default Conference;