import alexRemennikov from './../assets/avatars/AlexRemennikov.jpg';
import chaoZhang from './../assets/avatars/ChaoZhang.jpg';
import davidHastie from './../assets/avatars/DavidHastie.jpg';
import guangqingZhang from './../assets/avatars/GuangqingZhang.jpg';
import guangyaoSi from './../assets/avatars/GuangyaoSi.jpg';
import hanHuang from './../assets/avatars/HanHuang.jpg';
import jiangqianZhang from './../assets/avatars/JianqiangZhang.jpg';
import jonRoberts from './../assets/avatars/JonRoberts.jpg';
import mehmetKizil from './../assets/avatars/MehmetKizil.jpg';
import lihongSu from './../assets/avatars/LihongSu.jpg';
import MingQiao from './../assets/avatars/MingQiao.jpg';
import MingyuanLU from './../assets/avatars/MingyuanLU.jpg';
import muratKarakus from './../assets/avatars/MuratKarakus.jpg';
import PeterKnights from './../assets/avatars/PeterKnights.jpg';
import RichardYang from './../assets/avatars/RichardYang.jpg';
import SarahZhang from './../assets/avatars/SarahZhang.jpg';
import TingRen from './../assets/avatars/TingRen.jpg';
import VishnuPareek from './../assets/avatars/VishnuPareek.jpg';
import XianghongYu from './../assets/avatars/XianghongYu.jpg';
import YoonJeongWhan from './../assets/avatars/YoonJeongWhan.jpg';
import ZhengyiJiang from './../assets/avatars/ZhengyiJiang.jpg';
import HelenJiang from './../assets/avatars/Helen.jpg'
import ShivakumarKarekal from './../assets/avatars/ShivakumarKarekal.jpg'
import ChihlingLin from './../assets/avatars/ChihlingLin.jpg'
import HalGurgenci from './../assets/avatars/HalGurgenci.jpg'
import OliviaMirza from './../assets/avatars/OliviaMirza.jpg'
import SimonRoss from './../assets/avatars/SimonRoss.jpg'
import ValerieLinton from './../assets/avatars/ValerieLinton.jpg'
import XueqiuHe from './../assets/avatars/XueqiuHe.jpg'
import MuyuanZhou from './../assets/avatars/MuyuanZhou.jpg'
import FanWang from './../assets/avatars/FanWang.jpg'
import HaoranLi from './../assets/avatars/HaoranLi.jpg'
import LisongZhu from './../assets/avatars/LisongZhu.jpg'
import XiaZhao from './../assets/avatars/XiaZhao.jpg'
import EdwinCJGan from './../assets/avatars/EdwinCJGan.jpg'
import HongyuXiao from './../assets/avatars/HongyuXiao.jpg'
import QingxueHuang from './../assets/avatars/QingxueHuang.jpg'
import JipeiCheng from './../assets/avatars/JipeiCheng.jpg'

export const unis = {
  unsw: {
    name: "University of New South Wales"
  },
  uoa: {
    name: "University of Adelaide"
  },
  uow: {
    name: "University of Wollongong"
  },
  uq: {
    name: "The University of Queensland"
  },
  wsu: {
    name: "Western Sydney University"
  },
  curtin: {
    name: "Curtin University"
  },
  deakinuni: {
    name: "Deakin University"
  },
}

export const management = [
  {
    title: "Distinguished Prof", 
    name: "Zhengyi Jiang",
    position: "Centre Director", 
    avatar: ZhengyiJiang,
    tel: "+61 2 4221 4545",
    email: "zhengyi_jiang@uow.edu.au",
    url: "https://scholars.uow.edu.au/zhengyi-jiang"
  },
  {
    title: "Prof", 
    name: "Ting Ren",
    position: "Deputy Director, Program THREE Lead", 
    avatar: TingRen,
    tel: "+61 2 4221 4168",
    email: "ting_ren@uow.edu.au",
    url: "https://scholars.uow.edu.au/ting-ren"
  },
  {
    title: "Dr", 
    name: "Lihong Su",
    position: "Assistant Director", 
    avatar: lihongSu,
    tel: "+61 2 4221 5397",
    email: "lihong_su@uow.edu.au",
    url:"https://scholars.uow.edu.au/lihong-su"
  },
  {
    title: "Prof", 
    name: "Jeong Yoon",
    position: "Program ONE Lead", 
    avatar: YoonJeongWhan,
    tel: "+61 3 5227 3425",
    email: "j.yoon@deakin.edu.au",
    url: "https://www.deakin.edu.au/about-deakin/people/jeong-yoon"
  },
  {
    title: "Prof", 
    name: "Han Huang",
    position: "Program TWO Lead", 
    avatar: hanHuang,
    tel: "+61 7 3365 3583",
    email: "han.huang@uq.edu.au",
    url: "https://mechmining.uq.edu.au/profile/386/han-huang"
  },
  {
    title: "Prof", 
    name: "Murat Karakus",
    position: "Program FOUR Lead", 
    avatar: muratKarakus,
    tel: "+61 8 8313 6471",
    email: "murat.karakus@adelaide.edu.au",
    url: "https://www.adelaide.edu.au/directory/murat.karakus"  
  },
  {
    title: "Ms", 
    name: "Helen Jiang",
    position: "Business Manager", 
    avatar: HelenJiang,
    tel: "+61 42392364",
    email: "helenj@uow.edu.au",
    url: ""
  },
]

export const chiefInvestigators = [
    {
      title: "Prof",
      name: "Ting Ren",
      avatar: TingRen,
      uni: "uow",
      url: "https://scholars.uow.edu.au/ting-ren"
    },
    {
      title: "Prof",
      name: "Han Huang",
      avatar: hanHuang,
      uni: "uq",
      url: "https://mechmining.uq.edu.au/profile/386/han-huang"
    },
    {
      title: "Prof",
      name: "Jeong Whan Yoon",
      avatar: YoonJeongWhan,
      uni: "deakinuni",
      url: "https://www.deakin.edu.au/about-deakin/people/jeong-yoon"
    },
    {
      title: "A/Prof",
      name: "Murat Karakus",
      avatar: muratKarakus,
      uni: "uoa",
      url: "https://www.adelaide.edu.au/directory/murat.karakus"  
    },
    {
      title: "Prof",
      name: "Richard Yang",
      avatar: RichardYang,
      uni: "wsu",
      url: "https://www.westernsydney.edu.au/staff_profiles/uws_profiles/Prof_richard_yang"
    },
    {
      title: "Prof",
      name: "Sarah Zhang",
      avatar: SarahZhang,
      uni: "wsu",
      url: "https://www.westernsydney.edu.au/staff_profiles/WSU/Prof_sarah_zhang"
    },
    {
      title: "Prof",
      name: "Jianqiang Zhang",
      avatar: jiangqianZhang,
      uni: "unsw",
      url: "https://www.unsw.edu.au/staff/jianqiang-zhang"
    },
    {
      title: "Prof",
      name: "Vishnu Pareek",
      avatar: VishnuPareek,
      uni: "curtin",
      url: "https://staffportal.curtin.edu.au/staff/profile/view/vishnu-pareek-6b73f5f5/"
    },
    {
      title: "Prof",
      name: "Peter Knights",
      avatar: PeterKnights,
      uni: "uq",
      url: "https://researchers.uq.edu.au/researcher/1475"
    },
    {
      title: "Prof",
      name: "Chao Zhang",
      avatar: chaoZhang,
      uni: "uow",
      url: "https://scholars.uow.edu.au/chao-zhang"
    },
    {
      title: "Prof",
      name: "Alex Remennikov",
      avatar: alexRemennikov,
      uni: "uow",
      url: "https://scholars.uow.edu.au/alex-remennikov"
    },
    {
      title: "A/Prof",
      name: "Mehmet Kizil",
      avatar: mehmetKizil,
      uni: "uq",
      url: "https://researchers.uq.edu.au/researcher/528"
    },
    {
      title: "A/Prof",
      name: "Shivakumar Karekal",
      avatar: ShivakumarKarekal,
      uni: "uow",
      url: "https://scholars.uow.edu.au/shivakumar-karekal"
    },
    {
      title: "Dr",
      name: "Lihong Su",
      avatar: lihongSu,
      uni: "uow",
      url:"https://scholars.uow.edu.au/lihong-su"
    },
    {
      title: "Dr",
      name: "David Hastie",
      avatar: davidHastie,
      uni: "uow",
      url: "https://scholars.uow.edu.au/david-hastie"
    },
    {
      title: "Dr",
      name: "Guangqing Zhang",
      avatar: guangqingZhang,
      uni: "uow",
      url: "https://scholars.uow.edu.au/guangqing-zhang"
    },
    {
      title: "Dr",
      name: "Mingyuan Lu",
      avatar: MingyuanLU,
      uni: "uq",
      url: "https://researchers.uq.edu.au/researcher/12274"
    },

    {
      title: "Dr",
      name: "Jon Roberts",
      avatar: jonRoberts,
      uni: "uow",
      url: "https://scholars.uow.edu.au/jon-roberts"
    },
    {
      title: "Dr",
      name: "Guangyao Si",
      avatar: guangyaoSi,
      uni: "unsw",
      url: "https://research.unsw.edu.au/people/dr-guangyao-si"
    },
  ]
export const partnerInvestigators = [
    {
      title: "Dr",
      name: "Dake Yu",
      company: "Bisalloy Steels Pty Ltd"
    },
    {
      title: "Dr",
      name: "Henry Gong",
      company: "Roobuck Pty Ltd"
    },
    {
      title: "Dr",
      name: "Shipeng Ding",
      company: "SNS UniCorp Pty Ltd"
    },
    {
      title: "Dr",
      name: "Tianwu Liu",
      company: "HBIS Group Co., Ltd"
    },
    {
      title: "Dr",
      name: "Enrui Wang",
      company: "HBIS Group Co., Ltd"
    },
    {
      title: "Ms",
      name: "Fan Wang",
      company: "HBIS Group Co., Ltd"
    },
    {
      title: "Prof",
      name: "Sihai Jiao",
      company: "Baosteel Company"
    },
    {
      title: "",
      name: "Yongzhu Ma",
      company: "Baosteel Company"
    },
    {
      title: "Mr",
      name: "Tony de Sousa",
      company: "Komastu Australia Pty Ltd"
    },
    {
      title: "",
      name: "Rudie Boshoff",
      company: "Komastu Australia Pty Ltd"
    },
    {
      title: "Mr",
      name: "Robin Liu",
      company: "Top Iron Pty Ltd"
    },
    {
      title: "Mr",
      name: "Jianzhong Sun",
      company: "Australia L&Y Mine Equipment Manufacturing Pty Ltd"
    }
  ]
 export const fellow = [
  {
    title: "Dr",
    name: "Xianghong Yu",
    uni: "uow",
    avatar: XianghongYu,
    url: "https://www.linkedin.com/in/xianglong-yu-12407a55/"
  },
  {
    title: "Dr",
    name: "Ming Qiao",
    uni: "uow",
    avatar: MingQiao,
    url: "https://scholars.uow.edu.au/ming-qiao"
  },
  {
    title: "Dr",
    name: "Chih-Ling Lin",
    uni: "uq",
    avatar: ChihlingLin,
    url: "https://mechmining.uq.edu.au/profile/671/chih-ling-jenny-lin"
  }
]
export const phdCandidate = [
    {
      name: "Haoran Li",
      uni: "uow",
      type: "HDR_1",
      commencementDate: "March 2023",
      avatar: HaoranLi,
      plannedCompletionDate: "Dec 2026",
      topics: "Implementing deep learning in bioinformatics for neurodegenerative diseases research starting from Huntington's Disease",
      primarySupervisor: "Jun Shen",
      coSupervisors: "Zhengyi Jiang, Bo Du, Jiahua Shi"
    },
    {
      name: "Muyuan Zhou",
      uni: "uow",
      type: "HDR_2",
      commencementDate: "Mar 2023",
      avatar: MuyuanZhou,
      plannedCompletionDate: "Dec 2026",
      topics: "Mechanism and practical research on surface quality improvement of hot rolled plate steels under high chloridion water-based nanolubricants",
      primarySupervisor: "Zhengyi Jiang",
      coSupervisors: "Hui Wu"
    },
    {
      name: "Peijun Zheng",
      uni: "uow",
      type: "HDR_3",
      commencementDate: "July 2024",
      plannedCompletionDate: "Dec 2027",
      topics: "Investigation of electrical and thermal properties of advanced materials",
      primarySupervisor: "Chao Zhang",
      coSupervisors: ""
    },
    {
      name: "Lisong Zhu",
      uni: "uow",
      type: "HDR_4",
      commencementDate: "March 2024",
      avatar: LisongZhu,
      plannedCompletionDate: "Sep 2027",
      topics: "Manufacturing of advanced metallic materials via powder metallurgy hot isostatic pressing",
      primarySupervisor: "Zhengyi Jiang",
      coSupervisors: "Hui Wu"
    },
    {
      name: "Edwin Chern Junn GAN",
      uni: "uow",
      type: "ICHDR_1",
      commencementDate: "Jan 2024",
      plannedCompletionDate: "Jul 2027",
      avatar: EdwinCJGan,
      topics: "Protection from projectiles resulting from large mine gas explosions using innovative composites",
      primarySupervisor: "Alex Remennikov",
      coSupervisors: "Ting Ren"
    },
    {
      name: "Xiao Zhao",
      uni: "uow",
      type: "ICHDR_2",
      commencementDate: "Mar 2024",
      plannedCompletionDate: "Sep 2027",
      avatar: XiaZhao,
      topics: "",
      primarySupervisor: "Ting Ren",
      coSupervisors: "Jon Roberts"
    },
    {
      name: "Shenshi Jiang",
      uni: "uow",
      type: "ICHDR_3",
      commencementDate: "recruitment",
      plannedCompletionDate: "Sep 2027",
      topics: "",
      primarySupervisor: "Guangqing Zhang",
      coSupervisors: ""
    },
    {
      name: "Hongyu Xia",
      uni: "deakinuni",
      type: "ICHDR_6",
      commencementDate: "Jul 2024",
      plannedCompletionDate: "Jul 2028",
      topics: "Innovation in Material Design, Fabrication and Evaluation of Performances",
      primarySupervisor: "Jeong Yoon",
      avatar: HongyuXiao,
      coSupervisors: "Bernard Rolfe, Shunying Zhang  "
    },
    {
      name: "Volkan Satar",
      uni: "uoa",
      type: "ICHDR_14",
      commencementDate: "Oct 2024",
      plannedCompletionDate: "",
      topics: "Advanced Simulation of Dynamic Rock Cutting Process for Next-Generation Excavation Tools",
      primarySupervisor: "Murat Karakus",
      coSupervisors: "Giang Nguyen, Ting Ren, Mehmet Kizil"
    },
    {
      name: "Jiacheng Wang",
      uni: "unsw",
      type: "ICHDR_13",
      commencementDate: "Jan 2025",
      plannedCompletionDate: "",
      topics: "Steel Degradation in Supercritical Carbon Dioxide Environments",
      primarySupervisor: "Jianqiang Zhang",
      coSupervisors: "Runyu Yang"
    },
    {
      name: "Jipei Chen",
      uni: "uow",
      type: "ICHDR_5",
      commencementDate: "Aug 2024",
      plannedCompletionDate: "Dec 2027",
      topics: "Development of Rock Cutting Simulation Methods for Hard Rock Mining Machines",
      primarySupervisor: "Jon Roberts",
      avatar: JipeiCheng,
      coSupervisors: "David Hastie"
    },
    {
      name: "",
      uni: "deakinuni",
      type: "ICHDR_7",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Jeong Yoon",
      coSupervisors: ""
    },
    {
      name: "",
      uni: "wsu",
      type: "ICHDR_8",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Sarah Zhang/Richard Yang",
      coSupervisors: ""
    },
    {
      name: "",
      uni: "uq",
      type: "ICHDR_9",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Peter Knights",
      coSupervisors: ""
    },
    {
      name: "",
      uni: "uq",
      type: "ICHDR_10",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Mehmet Kizil",
      coSupervisors: ""
    },
    {
      name: "",
      uni: "curtin",
      type: "ICHDR_11",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Vishnu Pareek",
      coSupervisors: ""
    },
    {
      name: "",
      uni: "unsw",
      type: "ICHDR_12",
      commencementDate: "",
      plannedCompletionDate: "",
      topics: "",
      primarySupervisor: "Guangyao Si",
      coSupervisors: ""
    },
  ]

export const advisoryCommittee = [
  {
    name: "Hal Gurgenci (Chair)",
    title: "Emeritus Prof",
    avatar: HalGurgenci,
    position: "School of Mechanical and mining engineering The University of Queensland",
    url: "https://researchers.uq.edu.au/researcher/168"
  },
  {
    name: "Olivia Mirza (Acting Chair Jan - Oct 2024)",
    title: "Prof",
    avatar: OliviaMirza,
    position: "Associate Dean Engagement, University of Western Sydney; President of Engineers Australia (Sydney)",
    url: "https://www.westernsydney.edu.au/dvcresearch/dvc_research/research_theme_program/research_theme_champions/olivia_mirza"
  },
  {
    title: "Prof",
    avatar: QingxueHuang,
    name: "Qingxue Huang",
    position: "Academician, China Academy of Engineering",
    url: "https://en.wikipedia.org/wiki/Huang_Qingxue"
  }, 
  {
    title: "Prof",
    avatar: XueqiuHe,
    name: "Xueqiu He",
    position: "University of Science and Technology Beijing China",
    url: "https://www.icmsse.com/js/5996.pdf"
  }, 
  {
    title: "Prof",
    avatar: ValerieLinton,
    name: "Valerie Linton Provost",
    position: "University of Auckland New Zealand",
    url: "https://www.auckland.ac.nz/en/about-us/about-the-university/the-university/governance-and-committees/management-team/provost.html"
  }, 
  {
    title: "Ms",
    avatar: FanWang,
    name: "Fan Wang",
    position: "Manager, Scientific & Technological Innovation Department, HBIS",
    url: ""
  }, 
  {
    title: "Prof",
    avatar: SimonRoss,
    name: "Simon Ross",
    position: "Dean of Graduate Research UOW",
    url: "https://scholars.uow.edu.au/simon-moss"
  }, 
  {
    title: "Distinguished Prof",
    avatar: ZhengyiJiang,
    name: "Zhengyi Jiang (non-voting member)",
    position: "Director ARC Innovative Composites for Sustainable Mining",
    url: "https://scholars.uow.edu.au/zhengyi-jiang"
  }, 
  {
    title: "Ms",
    avatar: HelenJiang,
    name: "Helen Jiang (non-voting member)",
    position: "Business Manager ARC Innovative Composites for Sustainable Mining",
    url: ""
  }, 
]