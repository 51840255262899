import React, { useState } from "react";
import ManageTeam from "../components/ManageTeam";
import Partners from "../components/Partners";
import AdvisoryTeam from "../components/AdvisoryTeam";
import meeting from './../assets/image/overview.jpg';

const Overview = () => {

  const [active, setActive] = useState(0);
  const tabs = [
    "About the Centre",
    "Centre Management Team",
    "Our Partners",
  ]

  return (
    <div style={{minHeight: "calc(100vh - 80px)", padding: "5px 5px 0 5px"}}>
      <div className="md:flex md:flex-row">
        <div className="text-grey-500 md:w-1/4 md:text-right">
          {[...tabs].map((tab, index) => {
            return (
              <div key={`tab-${index}`} 
                  onClick={() => {setActive(index)}}
                  className={`${active === index && "text-light-orange cursor-pointer border-l-2 md:border-l-0 md:border-r-2 border-light-orange"} py-2 pr-4 pl-2 hover:underline`}
                  >
                <span className="" >{tab}</span>
              </div>
            )
          })}
        </div>
        <div className="bg-gray-300 min-w-px hidden md:block md:mr-4 pl-3 md:pl-0" />
        <div className="md:w-3/4 mt-4 md:mt-0">
          <section className={`leading-7 text-gray-800 ${active !== 0 ? "hidden" : ""}`}>
            <h1 className="text-2xl font-bold font-sans">About the Centre</h1>
            <p className="pt-4">
              The Australian Research Council (ARC) Training Centre for Innovative Composites for the Future of Sustainable Mining Equipment (Innovative Composites for Sustainable Mining) is a 5 year and $13million ARC major investment (May 2023 to April 2028). 
            </p>
            <p className="pt-4">
              Led by the University of Wollongong, the ARC Innovative Composites for Sustainable Mining (the Centre) will bring together six Universities, namely the University of Queensland, University of NSW, the University of Adelaide, Deakin University, Curtin University, Western Sydney University, and nine industry partner organisations: Roobuck, Bisalloy, SNS Unicorp, Top Iron, Komatasu, Baosteel, HBIS, CCTEG Azure Mining Technology Pty Ltd and Australia L&Y.  
            </p>
            <p className="py-4">
              The Centre aims to train industry-focused researchers in advanced manufacturing of new-generation mining equipment and sustainable mining technology, through close collaborations among university partners and mining and manufacturing companies. By working closely with industry and community, the Centre will deliver valued-added technologies and products into the Australian METS sector, as well as produce a team of world class academic and industry leaders that meet future workforce demands and generate new technologies to support sustainable and cost effective mining equipment and high efficiency mining. These outcomes will significantly benefit the nation’s manufacturing and mining sectors and enhance the competitiveness of the Australian advanced manufacturing and mining industries.
            </p>
            <img src={meeting} alt="arc-meeting" className="w-4/5 md:w-lg block m-auto py-5"/>
            <h1 className="text-xl font-bold font-sans">Advisory Committee</h1>
            <AdvisoryTeam />

          </section>
          <section className={`${active !== 1 ? "hidden" : ""}`}>
            <h1 className="text-2xl font-bold font-sans">Centre Management Team</h1>
            <ManageTeam contact={true} />
          </section>
          <section className={`${active !== 2 ? "hidden" : ""}`}>
            <h1 className="text-2xl font-bold font-sans">Our Partners</h1>
            <Partners />
          </section>
        </div>
      </div>
    </div>
  )
}

export default Overview;